import React from "react";
import "../style/custom/admin-login.scss";

export default function Theme(props) { 
  return (
    <div
      className="vw-admin-login"
    >
      <div className="vw-login-main container-fluid fh" 
        style={{ minHeight: "100vh" }
      }>
        <div className="row align-items-center no-gutters fh-element">
          <div className="col login-first-panel-main">
            <div className="container left-panel">
              <div className="d-flex flex-column text-align-center justify-content-center">
                <section className="container login-main-logo-og">
                  <a href="https://www.superloop.com/" target="_blank">
                    <img
                      src="https://cdn.vostro.app/cdn/hotspots/superloop-free-wifi/SL-Logo-Horz-RGB.svg"
                      alt="logo"
                      className="main-logo"
                    />
                  </a>
                </section>
                <div className="container d-flex flex-column align-items-left justify-content-center login-main-content">
                  <div className="login-first-text">
                    <p className="login-text-heading">{'Welcome to'} <br/>
                      {'Superloop Wi-Fi'}
                    </p>
                  </div>
                  <div className="login-second-text">
                    <p className="login-text-subheading">{'Superloop exists to unleash the unlimited'} <br/>
                      {'possibilities of the internet.'}
                    </p>
                    <div className="login-rectangle-div"></div>
                  </div>
                </div>
                <div className="container login-form-footer">  
                  {`© Superloop ${new Date().getFullYear()}. All rights reserved.`}
                </div>
              </div>
            </div>
          </div>
          <div className="col login-second-panel-main">
            <div className="container right-panel">
              <div className="d-flex flex-column text-align-center justify-content-center">
                <section className="container login-main-logo-white">
                  <a href="https://www.superloop.com/" target="_blank">
                    <img
                      src="https://cdn.vostro.app/cdn/hotspots/superloop-free-wifi/sl_logo white.svg"
                      alt="logo"
                      className="main-logo-white"
                    />
                  </a>
                </section>
                {props.children}
                <div className="container login-form-footer">  
                  <span className="footer-text">
                    {`© Superloop ${new Date().getFullYear()}. All rights reserved.`}
                  </span>
                </div>
              </div>   
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
